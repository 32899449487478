import * as THREE from "three"
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { env } from "process";
import { environment } from '../environments/environment.js';

export const setBallSymbols = (scene, ballPosition, color) => {

    let assetsUrl =  environment.ASSETS_PATH;
    let fontMapper = {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        '9': '9',
        '10': '0',
        '11': 'a',
        '12': 'b',
        '13': 'c',
        '14': 'd',
        '15': 'e',
        '16': 'f', 
    };
    /**
     * Creates the meridians accordigs to params
     * @param {Font to be used} font 
     * @param {Letters to render} message 
     * @param {Material} material 
     */
    function createMeridians(font, message, material) {
        const cardsGroup = new THREE.Group(); // Group to hold all cards
        cardsGroup.name = 'symbols';
        const sphereRadius = 5;
        const numberOfSymbols = 8; //message.length;
        const numberOfMeridians = 8;
        let letterCount = 0;
        
        for (let t = 0; t < numberOfMeridians; t++) {
            const cardsAxis = new THREE.Group(); 
            for (let i = 0; i < numberOfSymbols; i++) {
                const phi = ((i / numberOfSymbols) * Math.PI *0.92); // Calculate angle along the meridian
                const x = sphereRadius * Math.cos(phi);
                const y = sphereRadius * Math.sin(phi);
                const shapes = font.generateShapes( message[letterCount], 0.6 );
                letterCount++;
                if(letterCount == message.length) letterCount = 0 //, console.log("MENSAJE MENOR A ESPACIOS");
                const geometry = new THREE.ShapeGeometry( shapes );
                geometry.computeBoundingBox();
                const xMid = - 0.5 * ( geometry.boundingBox.max.x - geometry.boundingBox.min.x );
                const yMid = - 0.5 * ( geometry.boundingBox.max.y - geometry.boundingBox.min.y );
                geometry.translate( xMid, yMid, 0 );
                geometry.rotateZ(Math.PI);
                geometry.rotateY(Math.PI);
                geometry.center();
                const card = new THREE.Mesh(geometry, material);
                // Set the position of the card along the sphere
                card.position.set(x, y, 0);
                const angleToCenter = Math.atan2(-x, 0);
                card.rotation.x = angleToCenter;
                card.lookAt(cardsGroup.position);
        
                cardsAxis.add(card);
            }
            cardsGroup.add(cardsAxis);
            cardsAxis.rotation.z = -(Math.PI*0.9);
            cardsAxis.rotation.x =  (t / numberOfMeridians) * Math.PI *2;
        }
        cardsGroup.rotateZ(Math.PI / 2);
        cardsGroup.position.set( ballPosition.x, ballPosition.y, ballPosition.z );
        scene.add(cardsGroup);
    }

    function mapLetters(inputArray, mapping) {
        const resultArray = [];
      
        for (const letter of inputArray) {
          if (mapping.hasOwnProperty(letter)) {
            resultArray.push(mapping[letter]);
          } else {
            resultArray.push(letter);
          }
        }
      
        return resultArray;
    }


    /// FONT LOADER
    const loader = new FontLoader();
    const fontFamily = assetsUrl + 'Liroluvilui_Regular.json'// 'font.json'; // 'egres_negativas_Regular.json'
    loader.load(fontFamily , function ( font ) {
            
        const matLite = new THREE.MeshBasicMaterial( {
            color: color,
            side: THREE.DoubleSide
        });
        const message = '1,2,15,16,14,13,8,3,4,1,6,1,2,15,16,14,13,8,3,4,1,6,1,2,15,16,14,13,8,3,4,1,6';
        const mappedMessage = mapLetters(message.split(','), fontMapper);
        //console.log(mappedMessage)
        let symbolsString = mappedMessage.join('');
        
        createMeridians(font, symbolsString, matLite);
    })

}

export const removeSymbolsGroup = function(scene) {
    const symbols = scene.getObjectByName('symbols');
    if(symbols) scene.remove(symbols);
}