import * as THREE from "three"
import { XYZLoader } from 'three/examples/jsm/loaders/XYZLoader';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { environment } from '../environments/environment.js';

export const setPinealGland = (scene, userName) => {
    // Liroluvilui

    let assetsUrl =  environment.ASSETS_PATH;
    let fontMapper = {    
    };
    /* EGRES MAPPER
    let fontMapper = {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        '9': '9',
        '10': '0',
        '11': 'a',
        '12': 'b',
        '13': 'c',
        '14': 'd',
        '15': 'e',
        '16': 'f', 
    };
    */
    let ballRadius = 3;

    function createHelixBall(font, message, material) {
        if (!message) return;
        const cardsGroup = new THREE.Group();
        var tanh = Math.tanh || function tanh(x) {
            return (Math.exp(x) - Math.exp(-x)) / (Math.exp(x) + Math.exp(-x));
        }; 
        var cosh = Math.cosh || function cosh(x) {
            return (Math.exp(x) + Math.exp(-x)) / 2;
        }; 
        var sinh = Math.sinh || function sinh(x) {
            return (Math.exp(x) - Math.exp(-x)) / 2;
        };
        // sphere spiral
        var sz = 7, cxy = 11, cz = cxy * sz;
        var hxy = Math.PI / cxy, hz = Math.PI / cz;
        var r = ballRadius*2;
        
        let letterCount = 0;
        for (var i = -cz; i < cz; i++) {
            var lxy = i * hxy;
            var lz = i * hz;
            var rxy = r /  cosh(lz);
            var x = rxy * Math.cos(lxy);
            var y = rxy * Math.sin(lxy);
            var z = r * tanh(lz);
            //console.log(obj.geometry.attributes.position)
            const shapes = font.generateShapes( message[letterCount], 0.3 );
            letterCount++;
            if(letterCount == message.length) letterCount = 0; //, console.log("MENSAJE MENOR A ESPACIOS");
            const geometry = new THREE.ShapeGeometry( shapes );
            geometry.computeBoundingBox();
            const xMid = - 0.5 * ( geometry.boundingBox.max.x - geometry.boundingBox.min.x );
            const yMid = - 0.5 * ( geometry.boundingBox.max.y - geometry.boundingBox.min.y );
            geometry.translate( xMid, yMid, 0 );
            const card = new THREE.Mesh(geometry, material);
            card.position.set(x, y, z);
            card.lookAt(0,0,0);
            const angleToCenter = Math.atan2(-z, 0);
            card.rotation.z = -angleToCenter*2; //Math.PI;
            cardsGroup.add(card);
        }
        
        // Create Sphere Ball Pineal
        const sphere = new THREE.SphereGeometry( ballRadius-0.3, 32, 16 );
        const sphereB = new THREE.SphereGeometry( ballRadius+2, 32, 16 );
        const sphereC = new THREE.SphereGeometry( ballRadius+5, 32, 16 );
        const materialB = new THREE.MeshPhongMaterial( { 
            color: 0xFFFFFF, shininess: 10, emissiveIntensity: 0.1 ,
            transparent: true, opacity:0.1 } );

         const materialC = new THREE.MeshPhongMaterial( { 
            color: 0xFFFFFF, shininess: 10, 
            transparent: true, opacity:0.05 } );

        const materialSphere = new THREE.MeshPhongMaterial( { 
            color: 0xFFFFFF, emissiveIntensity: 0.3, emissive: 0xFFFFFF,
            transparent: true, opacity:0.06 } );
        let mesh = new THREE.Mesh( sphere, materialSphere );
        let meshb = new THREE.Mesh( sphereB, materialB );
        let meshc = new THREE.Mesh( sphereC, materialC );
        // Saco por ahora las esferas de la GL pineal, solo quedarán las letras
        //cardsGroup.add(mesh);
        //cardsGroup.add(meshb);
        //cardsGroup.add(meshc);
        cardsGroup.rotateX(Math.PI /2);
        cardsGroup.position.set(0,83,-17);

        let lightPineal = new THREE.PointLight( 0xffffff, 120, 150 );
        lightPineal.position.set(0,83,-17);
        cardsGroup.add( lightPineal );


        scene.add(cardsGroup);
    }

    function mapLetters(inputArray, mapping) {
        const resultArray = [];
      
        for (const letter of inputArray) {
          if (mapping.hasOwnProperty(letter)) {
            resultArray.push(mapping[letter]);
          } else {
            resultArray.push(letter);
          }
        }
      
        return resultArray;
    }

    function shuffleName(name) {
        let nameSt = name.replace(/\s/g, '').toLowerCase();
        let arrayName = nameSt.split('');
        for (let i = arrayName.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [arrayName[i], arrayName[j]] = [arrayName[j], arrayName[i]];
        }
        return arrayName.join('');
    }

 /// FONT LOADER
 const loader = new FontLoader();
 const fontFamily = assetsUrl + 'Liroluvilui_Regular.json'; // 'egres_negativas_Regular.json'
 loader.load(fontFamily , function ( font ) {
         
     const matLite = new THREE.MeshBasicMaterial( {
         color: 0xFFFFFF,
         side: THREE.DoubleSide
     });
     const message = shuffleName(userName); //'ROBERTOCARLOSHUMBERTO';
     //console.log("NAME SHUFFLED :" + message);
     const mappedMessage = mapLetters(message.split(), fontMapper);
     //console.log(mappedMessage)
     let symbolsString = mappedMessage.join('');
     
     createHelixBall(font, symbolsString, matLite);
 })

}