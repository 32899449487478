import * as THREE from "three"
// Core boilerplate code deps
import { createCamera, createComposer, createRenderer, runApp } from "./components/core-utils"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { VertexNormalsHelper } from 'three/examples/jsm/helpers/VertexNormalsHelper';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { setPinealGland } from "./components/pineal"
import { setBallSymbols, removeSymbolsGroup } from "./components/ball"
import { gsap } from "gsap";
global.THREE = THREE
import Swal from 'sweetalert2';
import { environment } from './environments/environment.js';
//////////////////


let scene,light, lightb, lightc;
let initPos, animPos, lineGeometry,mesh1, mesh2, brain;
let raycaster, mouse, cameraControls, clikEvent = true;
let meshRaycast, meshBrain,initSound, toggleSingleBall, playSound;
let animLight = 0;
let stepOne, stepTwo,stepThree, stepFour, stepFive, stepInit = false;
let mapGroups;
let tiltLight;
let panel = document.getElementById('panel');
let panelText = panel.querySelector('.texto');
let panelTitle = panel.querySelector('.titulo');
let endpanel;
let setBrainVisibility;
let lightHelpersActive = false;
let cameraHelperActive = false;
let tiltBallsLight = false;
let t2 = gsap.timeline();
let currentBall;
let animatePanel, removeBalls, isSoundPlaying;

let assetsUrl =  environment.ASSETS_PATH;

let xL = 0;
let yL = 0;
let zL = 0;

let speed = 0.03;
let step = 0;

let meshRaycaster1;
let meshRaycaster2;
let ballMeshes = [];
let songElements = [];
let initialCameraPos = { x:1.1863203205446653, y:93.10988514218957, z:-208.77959971821855 };
let currentStep = 0;
var panelTimeline = gsap.timeline();
var currentBallNumber;
const userProfile = {};

const setup = { brainmap: 'Empty', map: 'ritmos' };

scene = new THREE.Scene();

let renderer = createRenderer({ antialias: true }, (_renderer) => {
    // best practice: ensure output colorspace is in sRGB, see Color Management documentation:
    // https://threejs.org/docs/#manual/en/introduction/Color-management
    _renderer.outputEncoding = THREE.sRGBEncoding
})

let camera = createCamera(45, 1, 1000, { x: 0, y: 5, z: 25 }, { x: 0, y: 325, z: 355 });
// The RenderPass is already created in 'createComposer'
camera.position.set( 0, 150, 200 );
camera.lookAt({ x: 0, y: 25, z: 455 });
if(cameraHelperActive) {
    const camerahelper = new THREE.CameraHelper( camera );
    scene.add(camerahelper)
}
let composer = createComposer(renderer, scene, camera, (comp) => {  })

// Get Balls Profile from Query String
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
let ballNamesQ = urlParams.get('balls');
let colorsQ = urlParams.get('colors');
let soundsQ = urlParams.get('sounds');

if(!ballNamesQ || !colorsQ || !soundsQ) {
    Swal.fire({
        icon: "error",
        title: "Parametro incorrectos",
        text: "Revise los parametros de la URL",
      });
    return;
}

let ballNames = ballNamesQ.split(',');
let colors = colorsQ.split(',');
let sounds = soundsQ.split(',');
let pinealGlandOn = urlParams.get('pineal');
pinealGlandOn = pinealGlandOn == 'true' ? true : false;
let userName = urlParams.get('user');
if(ballNames.length != colors.length || ballNames.length != sounds.length) {
    consoles.log("Wrong params");
    Swal.fire({
        icon: "error",
        title: "Parametro incorrectos",
        text: "Revise los parametros de la URL",
      });
    return;
} else {
    let balls = [];
    ballNames.forEach((name, index) => {
        balls.push({ name: name, color: colors[index], sound: sounds[index] });
    });
    currentBallNumber = balls.length-1;
    userProfile.balls = balls;
    userProfile.name = userName;
}


const stepPanelData = (sphere) => [
    { step: 1, title:'Preparación', text: 'Orbite el cerebro usando el click y arrastrando lateralmente el mouse.'},
    { step: 2, title:'Esfera '+ sphere, text: 'Reconozca la ubicación de esta esfera en el cerebro, identificando su posición en la cabeza. Puede seguir orbitando.'},
    { step: 3, title:'Esfera '+ sphere +' - Visualización', text: 'Visualice la misma esfera en su propio cerebro. Una vez que tenga la imagen creada haga click en continuar.'},
    { step: 4, title:'Esfera '+ sphere +' - Sonido', text: 'Sienta el sonido de este pájaro como si fuera emitido desde la esfera. Puede cerrar los ojos y mantener la visualización mientras escucha el canto.'},
 ];

const brainBalls = [
    { x:-32,    y:103, z:-1.8,  sound:'canario',       color:0xFFEA1D, group:'ritmos',     name:'r1' },
    { x:0 ,     y:88, z:50,     sound:'jilguero',      color:0xFFEA1D, group:'ritmos',     name:'r2' },
    { x:-34,    y:70, z:24,     sound:'estornino',     color:0xFFEA1D, group:'ritmos',     name:'r3' },
    { x:7.5,    y:144, z:19.5,  sound:'jilguero2',      color:0xDE1AC0, group:'magia',      name:'m1' },
    { x:-18.5,  y:79, z:1.5,    sound:'picogordo1',     color:0xDE1AC0, group:'magia',      name:'m2' },
    { x:-37,    y:86, z:36,     sound:'petirrojo1',     color:0xDE1AC0, group:'magia',      name:'m3' },
    { x:-29.5,  y:71,   z:-54,  sound:'estornino2',     color:0x1DBBFF, group:'egres',      name:'e1' },
    { x:26.5,   y:136.5, z:-2,  sound:'ruisenor1',      color:0x1DBBFF, group:'egres',      name:'e2' },
    { x:-26,    y:77,   z:11,   sound:'canario1',       color:0x1DBBFF, group:'egres',      name:'e3' },
    { x:-11,    y:117, z:28,    sound:'jilguero1',      color:0xFF1D27, group:'tiempo',     name:'t1' },
    { x:13,     y:118, z:17.6,  sound:'picogordo1',     color:0xFF1D27, group:'tiempo',     name:'t2' },
    { x:-4,     y:53, z:25,     sound:'estornino2',     color:0xFF1D27, group:'tiempo',     name:'t3' },
    { x:-21.5,  y:86, z:24,     sound:'mosquitero1',    color:0x00fa04, group:'oidos',      name:'o1' },
    { x:18,     y:86, z:24,     sound:'estornino2',     color:0x00fa04, group:'oidos',      name:'o2' },
    { x:-52,    y:69.5, z:11,   sound:'ruisenor1',      color:0x00fa04, group:'oidos',      name:'o3' },
    { x:44,     y:69.5, z:11,   sound:'petirrojo1',     color:0x00fa04, group:'oidos',      name:'o4' },
    { x:-28.5,  y:111.5, z:4.5, sound:'estornino2',     color:0x00fa04, group:'oidos',      name:'o5' },
    { x:28,     y:111.5, z:4.5, sound:'jilguero2',      color:0x00fa04, group:'oidos',      name:'o6' },
    { x:-22.2,  y:101.3, z:37,  sound:'jilguero2',      color:0xf2fa00, group:'inaccion',   name:'i1' },
    { x:12.6,   y:117.2, z:11,  sound:'ruisenor1',      color:0xf2fa00, group:'inaccion',   name:'i2' },
    { x:0,      y:87.7, z:50,   sound:'petirrojo1',     color:0xf2fa00, group:'inaccion',   name:'i3' },
]

mapGroups = [...new Map(brainBalls.map(v => [v.group, v])).values()];
  
let app = {
    async initScene() {

        const listener = new THREE.AudioListener();
        camera.add( listener );
        this.controls = new OrbitControls(camera, renderer.domElement)
        this.controls.enableDamping = true
        this.controls.movementSpeed = 170;
        this.controls.lookSpeed = 0.9;
        this.controls.noFly = false;
        this.controls.lookVertical = true;
        this.controls.enableDamping = true;
        this.controls.target.y = 90;
        this.controls.update();
        
        scene.fog = new THREE.FogExp2( 0x000000, 0.0022 );
        scene.background = new THREE.Color( 0x05020B );
        light = new THREE.PointLight( 0xffffff, 20, 100 );
        light.position.set( 0, 180, 80 )
        scene.add( light );

        lightb = new THREE.PointLight( 0xffffff, 20, 100 );
        lightb.position.set( 0, 20, -120 )
        scene.add( lightb );

        lightc = new THREE.PointLight( 0xffffff, 20, 200 );
        lightc.position.set( 0, 150, -140 )
        scene.add( lightc );

        if(lightHelpersActive) {
            const lighthelper = new THREE.PointLightHelper( light, 6 );
            scene.add( lighthelper );
            const lighthelperb = new THREE.PointLightHelper( lightb, 6 );
            scene.add( lighthelperb );
            const lighthelperc = new THREE.PointLightHelper( lightc, 6 );
            scene.add( lighthelperc );
        }
        const ambient = new THREE.AmbientLight( 0x404040, 0.9 ); // soft white light
        scene.add( ambient );

        /**
         * TEXTURES
         */
        let spine = require('./assets/spine.jpg');
        let spine_alpha = require('./assets/spine_alpha.jpg');
        let exor = require('./assets/exor.jpg');
        const ballTextures = [spine, spine_alpha, exor];
        const textureBallsLoader = new THREE.TextureLoader();
        const textureBallPromises = ballTextures.map((texture) => {
            return new Promise((resolve, reject) => {
                textureBallsLoader.load(texture, resolve, undefined, reject);
            });
        });

        let textures;
        Promise.all(textureBallPromises)
        .then((textureMap) => {
            textures = textureMap;
            console.log(textures)
            //mapGroups.forEach(group => { setBalls(group.group, true, textures) });
            //toggleGroupBalls();
        }).catch((error) => {
            console.error('Error loading textures:', error);
        });

        /**
         * SPHERES
         */
        //let ballGroup = new THREE.Group();
       
        function setBalls(groupVisibility, toggle, textures) {
            let ballGroup = new THREE.Group();
            ballGroup.name = groupVisibility;
            if(toggle) {
                brainBalls.forEach(ball => {
                    if(ball.group == groupVisibility) createBall(ball, ballGroup, textures);
                })
                scene.add(ballGroup);
            }
        }
       
       
        function toggleGroupBalls(groupName = null) {
            mapGroups.forEach(group => {
                let gr = scene.children.find(i => i.name == group.group);
                if(gr) {
                    if(group.group != groupName) {
                        gr.visible = false; 
                    } else {
                        gr.visible = true;  
                    }
                }
            })
        }

        removeBalls = function (){
            brainBalls.forEach(item => {
                let gr = scene.children.find(i => i.name == item.name);
                if(gr) {
                    let ball = scene.getObjectByName(gr.name);
                    if( ball) scene.remove( ball );
                }
            })
            removeSymbolsGroup(scene);
        }

       toggleSingleBall = function (userBall){
            const ballMap = brainBalls.find(item =>  item.name == userBall.name);
            //console.log(ballMap)
            createBall( ballMap, userBall );
        }
 
        function createBall(ball, userBall) {
            let ballWrapper = new THREE.Group();
            ballWrapper.name = ball.name;
            const sphereA = new THREE.SphereGeometry( 3, 32, 16 );
            const sphereB = new THREE.SphereGeometry( 5, 32, 16 );
            const sphereC = new THREE.SphereGeometry( 8, 32, 16 );
            const materialA = new THREE.MeshPhongMaterial( { 
                color: Number(userBall.color), shininess: 10, emissive: Number(userBall.color), emissiveIntensity: 0.4, 
                transparent: true, opacity:0.6} );

            const materialB = new THREE.MeshPhongMaterial( { 
                    color: Number(userBall.color), shininess: 10, emissive: Number(userBall.color), emissiveIntensity: 0.2, 
                    transparent: true, opacity:0.2 } );

            const materialC = new THREE.MeshPhongMaterial( { 
                    color: Number(userBall.color), shininess: 10, 
                    transparent: true, opacity:0.08 } );

            const materialSpine = new THREE.MeshPhongMaterial( { 
                color: userBall.color, shininess: 1, //emissive:userBall.color, 
                side: THREE.DoubleSide, 
                map:textures[2], alphaMap: textures[1]} );
            /// material2 = new THREE.MeshPhongMaterial( { color: 0xbb55ff, shininess: 0, emissive: 0xbb55ff, emissiveIntensity: 0.3} );
            let meshA = new THREE.Mesh( sphereA, materialA );
            let meshB = new THREE.Mesh( sphereB, materialB );
            let meshC = new THREE.Mesh( sphereC, materialC );
            meshA.position.set(ball.x, ball.y, ball.z);
            meshB.position.set(ball.x, ball.y, ball.z);
            meshC.position.set(ball.x, ball.y, ball.z);
            meshA.name = ball.name;
            meshB.name = ball.name;
            meshC.name = ball.name;
            ballMeshes.push(meshA);
            
            // Spine of Firmas Angelicales symbols
            //const spinePlane = new THREE.PlaneGeometry(0.9,6);
            //let spine = new THREE.Mesh(spinePlane, materialSpine);
            //spine.position.set(ball.x, ball.y, ball.z);
            //setBallSymbols(scene, { x: ball.x, y: ball.y, z: ball.z });
            //ballWrapper.add( spine );

            setSound( userBall, meshA, userBall.color );
            
            ballWrapper.add( meshA );
            ballWrapper.add( meshB );
            ballWrapper.add( meshC );
            scene.add(ballWrapper);

        }

        if(pinealGlandOn) setPinealGland(scene, userName);

        function setSound(ball, mesh) {
            let name = ball.sound;
            const sound1 = new THREE.PositionalAudio( listener );
            let songElement = document.getElementById( name );
            songElements.push(songElement);
            sound1.setMediaElementSource( songElement );
            sound1.name = name;
            sound1.setRefDistance( 20 );
            sound1.setLoop(true);
            mesh.add( sound1 );  
        }
       
        playSound = function (step) {
            const sound1 = new THREE.Audio( listener );
            let songElement = document.getElementById( step );
            //songElements.push(songElement);
            sound1.setMediaElementSource( songElement );
            sound1.name = step;
            scene.add( sound1 );
            songElement.play();
        }

        /**
         * LINES
         */
        const lineGroup = new THREE.Group();
        function createLines(mapName) {
            const points = [];
            const mapBrain = brainBalls.filter(item => item.group == mapName);
            console.log(mapBrain)
            mapBrain.forEach(vector => points.push( new THREE.Vector3(vector.x, vector.y, vector.z) ));
            points.push(new THREE.Vector3(mapBrain[0].x, mapBrain[0].y, mapBrain[0].z));
            lineGeometry = new THREE.BufferGeometry().setFromPoints( points );
            let matLineBasic = new THREE.LineBasicMaterial( { color:mapBrain[0].color, linewidth: 1 } );
            const lined = new THREE.Line( lineGeometry, matLineBasic );
            lineGroup.add( lined );
           // addTube(points);
        }
      
        function addTube(points) {
            const pipeSpline = new THREE.CatmullRomCurve3( points )  ;  
            tubeGeometry = new THREE.TubeGeometry( pipeSpline, 100, 2, 6, true);
            addGeometry( tubeGeometry );

        }

        function addGeometry( geometry ) {
            const material = new THREE.MeshLambertMaterial( { color: 0xff00ff } );
            const wireframeMaterial = new THREE.MeshBasicMaterial( { color: 0x000000, opacity: 0.3, wireframe: true, transparent: true } );
            let mesh = new THREE.Mesh( geometry, material );
            const wireframe = new THREE.Mesh( geometry, wireframeMaterial );
            mesh.add( wireframe );
            scene.add( mesh );
        }

        function setLines(groupVisibility, toggle) {
            if(toggle) {
                mapGroups.forEach(mapGroup => {
                    if(mapGroup.group == groupVisibility) createLines(mapGroup.group);
                    lineGroup.add(scene)
             })
            } else {
                lineGroup.removeFromParent();
            }   
        }

         

        /**
         * FACE MODEL
         */
        const loader = new GLTFLoader();
        loader.load( assetsUrl + 'LeePerrySmith.glb', function ( gltf ) {
            const mesh = gltf.scene.children[ 0 ];
            mesh.geometry.computeTangents();
            mesh.scale.multiplyScalar(40)
            
           const material2 = new THREE.LineBasicMaterial({
                color: 0xFF9800, 
                side: THREE.FrontSide,
                transparent: true,
                opacity: 0.15,
                depthTest: false
            });

            let vnh = new VertexNormalsHelper( mesh, 0.3, 0xFF9800);
			scene.add( vnh );

            const edges = new THREE.EdgesGeometry( mesh.geometry );

        });


        /**
         * BRAIN MODEL
         */
        function loadModel() {
            const material = new THREE.MeshPhysicalMaterial({})
            material.thickness = 0.4
            material.roughness = 0.9
            material.clearcoat = 0.4
            material.clearcoatRoughness = 1
            material.transmission = 0.1
            material.alphaHash = true
            material.transparent= true
            material.opacity = 0.1
            material.ior = 3
            material.envMapIntensity = 1;


            const material2 = new THREE.MeshStandardMaterial({
                transparent: true,
                opacity: 0.01,
                transparent: true,
                transmission: 0.6,
                sampleLevel: 0.4,
                depthWrite: false, 
                alphaHash: true,
                side: THREE.FrontSide,
            })

          
            brain.traverse( function ( child ) {
                if ( child.isMesh ) {
                    meshBrain = new THREE.Mesh( child.geometry, material2 );
                    meshBrain.scale.setScalar( 0.53 );
                    meshBrain.position.y = 87;
                    meshBrain.position.z = 4;
                    meshBrain.position.x = -2;
                    meshBrain.rotation.y =  - Math.PI / 2;
                    meshBrain.name = 'brain';
                    scene.add( meshBrain );
                }
            });
        }
    
        
        const manager = new THREE.LoadingManager( loadModel );
        const loader2 = new OBJLoader(manager);
        loader2.load( assetsUrl + 'BrainUVs.obj', function ( obj ) {
            brain = obj;
        });

        
        /**
         * MOUSE INTERACTIONS
         **/
        raycaster = new THREE.Raycaster();
		mouse = new THREE.Vector2();
        //color = new THREE.Color();
        function onPointerClick( event ) {
            mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
            mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
            clikEvent = true;
        }

        document.addEventListener( 'click', onPointerClick );
        

        tiltLight = function(meshRaycast) {
            const lightTilt = [100,0,100,0,100,0];
            let lightForce = lightTilt[animLight];
            var tl = gsap.timeline();
            //console.log(meshRaycast)
            var tween = 
            tl.to(meshRaycast.material, { emissiveIntensity: lightTilt[0], duration: 0.3 })
              .to(meshRaycast.material, { emissiveIntensity: lightTilt[1], duration: 0.3 }, "+=2")
              .to(meshRaycast.material, { emissiveIntensity: lightTilt[2], duration: 0.3 }, "+=1")
              .to(meshRaycast.material, { emissiveIntensity: lightTilt[3], duration: 0.3 }, "+=3")
              .to(meshRaycast.material, { emissiveIntensity: lightTilt[4], duration: 0.3 }, "+=2")
              .to(meshRaycast.material, { emissiveIntensity: lightTilt[5], duration: 0.3 }, "+=3")
              .to(meshRaycast.material, { emissiveIntensity: 0, duration: 0.3 }, "+=5")
              .to(meshRaycast.material, { emissiveIntensity: 0, duration: 0.3 }, "+=1")
             tween.repeat(5);
        };

        initSound = function(songName) {
            let song = songElements.find(s => s.id == songName);
            if(song) console.log("SONG: " + song.isPlaying)
            if(song && !song.isPlaying) song.play();
        }

        isSoundPlaying = function() {
            return false;
            //console.log(scene.children)
            /*
            let songIsPlaying = false;
            songElements.forEach(song => {
                if(song && song.isPlaying) {
                    songIsPlaying = true;
                }
            })
            return songIsPlaying;
            */
        }

        setBrainVisibility = function(visible) {
            let br = scene.children.filter(i => i.name == 'brain');
            br.forEach(brainPart => brainPart.visible = visible);
        }

        animatePanel = function(stepNumber, fadeOut = false) {
            const panelLayer = document.getElementById('stepPanel');
            panelLayer.style.visibility = 'visible';
            if(fadeOut)  {
                gsap.to(panel, { opacity: 0, duration: 0.5, ease: "power3.inOut"});
                gsap.to(panel, { position:0, x: -300, duration: 0.5, ease: "power3.inOut"});
                return;
            }
           
            gsap.set(panel, { x: -300,  opacity: 0 });
            if(stepNumber == 0) {
                let stepData = stepPanelData(currentBallNumber+1);
                panelText.innerText = stepData[0].text;
                panelTitle.innerText = stepData[0].title;
                setTimeout(function(){
                    gsap.to(panel, { opacity: 1, duration: 1, ease: "power3.inOut"});
                    gsap.to(panel, { position: 0, x: 0, duration: 1, ease: "power3.inOut"});
                }, 1000);
                return;
            }

            let stepData = stepPanelData(currentBallNumber+1);
            panelText.innerText = stepData[stepNumber].text;
            panelTitle.innerText = stepData[stepNumber].title;

            gsap.to(panel, { opacity: 1, duration: 0.5, ease: "power3.inOut"});
            gsap.to(panel, { position: 0, x: 0, duration: 1, ease: "power3.inOut"});
        }

        // STEP Functions
        stepOne = function(controls) {
            if(!stepInit) {
                console.log('STEP 1');
                currentBall = userProfile.balls[currentBallNumber];
                removeBalls();   
                stepInit = true;
                camera.lookAt({ x: 0, y: 0, z: 0 });
                gsap
                .to(camera.position,{
                    duration: 2,
                    x: 0, y:150, z:200,
                    ease: "power3.inOut",
                    onComplete() {
                        toggleSingleBall(currentBall);
                        controls.autoRotate = true;
                        controls.enablePan = false;
                        controls.enableZoom = false;
                        if(tiltBallsLight) tiltLight(ballMeshes[0]);
                        meshBrain.material.opacity = 0.01;
                        //Animate brain opacity --removed
                        /* gsap.to(meshBrain.material, {
                            opacity: 0.01,
                            duration: 3,
                            stagger: { each: 2, yoyo: true, repeat: -1 },
                            ease: "sine.inOut"
                        });*/
                        animatePanel(1);

                    }
                });
                animatePanel(1, true);
            }
        }

        stepTwo = function(controls) {
            if(!stepInit) {
                animatePanel(2, true);
                stepInit = true;
                let step2SoundElement = document.getElementById( 'paso2' );
                console.log("STEP 2");
                controls.enabled = false;
                controls.autoRotate = false;
                t2.restart();
                t2
                .to(camera.position, {  x: initialCameraPos.x, y:initialCameraPos.y, z: initialCameraPos.z , duration: 2 }, "0")
                .to(controls.target, {  x: controls.target.x-5, y:controls.target.y+5, z: controls.target.z + 100 , duration: 4 }, "0")
                .to(meshBrain.material, { opacity: 0,duration:2 } )
                .to(camera.position, {  x: initialCameraPos.x-5, y:initialCameraPos.y, z: initialCameraPos.z+260 , duration: 4, 
                    onComplete(){
                        setBrainVisibility(false);
                        //meshBrain.material.opacity = 0;
                        animatePanel(2)
                    }
                }, "2" );       
            }      
        }

        stepThree = function(controls) {
            if(!stepInit) {
                animatePanel(3, true);
                stepInit = true;
                //console.log("ballName: "+JSON.stringify(ballName));
                console.log('STEP 3');
                setTimeout( () =>  {
                    animatePanel(3);
                    initSound(currentBall.sound);
                }, 2000);
            }
        }

        stepFour = function(controls) {
            if(!stepInit) {
                stepInit = true;
                animatePanel(3, true);
                console.log('STEP 4');
                //camera.lookAt({ x: 0, y: 325, z: 355 });
                controls.target.set(0, 90, 0); 
                removeBalls();
                controls.enabled = true;
                controls.autoRotate = true;
                gsap
                .to(camera.position,{
                    duration: 2,
                    x: 0, y:150, z:200,
                    ease: "power3.inOut",
                    onComplete() {
                        if(currentBallNumber == -1) {
                            stepInit = false;
                            currentStep = 5;
                            return;
                        }
                        animatePanel(3);
                        currentStep = 1;
                        stepInit = false;
                    }
                });
                setBrainVisibility(true);
                t2.set(meshBrain.material, { opacity: 0 } )
                //meshBrain.material.opacity = 0.07;
                
                gsap.to(meshBrain.material, {
                    opacity: 0.01,
                    duration: 2,
                    ease: "sine.inOut"
                });
            }      
        }

        stepFive = function(controls) {
            if(!stepInit) {
                animatePanel(4, true);
                stepInit = true;
                controls.autoRotate = false;
                console.log('STEP 5');
                setTimeout( () =>  {
                    endpanel.style.visibility = 'visible';
                    endpanel.style.opacity = 0;
                    gsap.to(endpanel, { opacity: 1, duration: 2, ease: "power3.inOut"});
                    //window.href = "https://www.google.com";
                }, 2000);
            }
        }
        
    
    },

    async updateScene() {
        xL = xL+1;
        yL = yL; 
        const points = [];
        points.push( initPos );
        points.push( animPos ); 
       
         if(currentStep == 1) {
            stepOne(this.controls);
         }
         
        if(currentStep == 2) {
           stepTwo(this.controls);
        }

        if(currentStep == 3) {
            stepThree(this.controls);
         }

         if(currentStep == 4) {
            stepFour(this.controls);
         }

         if(currentStep == 5) {
            stepFive(this.controls);
         }

        camera.updateProjectionMatrix();
        this.controls.update();

        if(ballMeshes.length > 0) {
            ballMeshes.forEach( mesh => {
                const intersection = raycaster.intersectObject( mesh );
                if ( intersection.length > 0 && clikEvent) {
                    step = 1;
                }
            })
        }

    }
}


function init() {
    const formLayer = document.getElementById( 'form' );
    formLayer.style.display = 'none';
    const frameLayer = document.getElementById('framer');
    frameLayer.style.visibility = 'visible';
    let nameForm =  document.querySelector('#nameInput');   
    userName = nameForm?.value ?? userProfile.name;
    console.log(userName)
    currentBallNumber = 0;
    endpanel =  document.getElementById( 'endPanel' );

    const step1Butt = document.getElementById( 'stepButt' );
    step1Butt.addEventListener('click', function() {
        if(isSoundPlaying()) return;
        stepInit = false;
        if(currentStep == 3) currentBallNumber++;
        if(currentBallNumber >= userProfile.balls.length) currentBallNumber = -1;
        currentStep++;
    });

    runApp(app, scene, renderer, camera, true, undefined, composer);
    setTimeout(function(){
        if(animatePanel) animatePanel(0);
    },1500);
        
}

const startButton = document.getElementById( 'startButton' );

startButton.addEventListener( 'click', init );

